import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryDialCode } from '@app/shared/models/country-dial-code';
import * as moment from 'moment';
import { GERMANY_COUNTRY_CODE } from './utils/constants';

export const checkPasswordVerification: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formStepOne = control.get('stepOne');
  const password = formStepOne.get('password');
  const passwordVerification = formStepOne.get('passwordVerification');
  const passwordMismatch = password && passwordVerification && password.value !== passwordVerification.value;

  if (passwordMismatch) {
    formStepOne.get('passwordVerification').setErrors({ passwordMismatch: true });
  }
  return password && passwordVerification && password.value !== passwordVerification.value
    ? { passwordMismatch: true }
    : null;
};

export function validEmail(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    )
      ? null
      : { email: true };
  };
}

export function checkPasswordHasLowerCase(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /[a-z]/.test(v) ? null : { passwordLowerCaseMissing: true };
  };
}

export function checkPasswordHasUpperCase(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /[A-Z]/.test(v) ? null : { passwordUpperCaseMissing: true };
  };
}

export function checkPasswordHasSpecialCharacter(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    const allowedCharsPattern = /[!#$@*.&]/;
    const allCharsPattern = /^[a-zA-Z0-9!#$@*.&]+$/;
    return allowedCharsPattern.test(v) && !/\s/.test(v) && allCharsPattern.test(v)
      ? null
      : { passwordSpecialCharMissing: true };
  };
}

export function checkPasswordHasNumbers(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /\d/.test(v) ? null : { passwordNumberMissing: true };
  };
}

export function checkWhitespace(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /.*\S+.*$/.test(v) ? undefined : { required: true };
  };
}

export function checkPasswordLength(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value ?? '';
    return v.length >= 10 ? null : { minlength: true };
  };
}
export function checkMaxLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value ?? '';
    if (v.length === 0 || v.length > maxLength) {
      return { maxlength: true };
    }
    return null;
  };
}

export function checkFieldNoNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /[0-9]/.test(v) ? { hasNumber: true } : null;
  };
}

export function checkPhoneNumberValid(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /^[0-9\s-]{4,43}$/.test(v) ? null : { phonePatternInvalid: true };
  };
}

export function checkZipCodeValidity(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /^[0-9]{4,10}$/.test(v) ? null : { zipCodePattern: true };
  };
}

export function checkPhoneLoginValidity(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: string = control.value;
    return /^(?:[+\d].*\d|\d)$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    )
      ? null
      : { required: true };
  };
}

export function emailMatchValidator(emailControlName: string, confirmEmailControlName: string) {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const emailControl = formGroup.get(emailControlName);
    const confirmEmailControl = formGroup.get(confirmEmailControlName);

    if (!emailControl || !confirmEmailControl) {
      return null;
    }

    if (emailControl.value.toLocaleLowerCase() !== confirmEmailControl.value.toLocaleLowerCase()) {
      confirmEmailControl.setErrors({ emailsMismatch: true });
    } else {
      confirmEmailControl.setErrors(null);
    }
    return null;
  };
}

export function validatePhoneNumberRegistration(
  phoneNumber: string,
  dialCodeList: CountryDialCode[]
): { dialCode: string | null; phoneNumber: string | null } {
  if (phoneNumber.startsWith('+')) {
    const dialCodeLength = [4, 3, 2].find(
      length => dialCodeList.findIndex(el => el.code === phoneNumber.substring(1, length)) !== -1
    );

    if (dialCodeLength) {
      const dialCode = phoneNumber.substring(1, dialCodeLength);
      const phoneNumberValue = phoneNumber.slice(dialCodeLength).replace(/\D/g, '').replace(/^0/, '');

      return { dialCode, phoneNumber: phoneNumberValue };
    }
  }

  if (phoneNumber.startsWith('00')) {
    const dialCodeLength = [5, 4, 3].find(
      length => dialCodeList.findIndex(el => el.code === phoneNumber.substring(2, length)) !== -1
    );

    if (dialCodeLength) {
      const dialCode = phoneNumber.substring(2, dialCodeLength);
      const phoneNumberValue = phoneNumber.slice(dialCodeLength).replace(/\D/g, '').replace(/^0/, '');

      return { dialCode, phoneNumber: phoneNumberValue };
    }
  }

  if (phoneNumber.startsWith('0') && phoneNumber[1] !== '0') {
    const phoneNumberValue = phoneNumber.slice(1);
    return { dialCode: null, phoneNumber: phoneNumberValue.replace(/\D/g, '') };
  }

  if (!phoneNumber.startsWith('0') && phoneNumber[0] !== '+') {
    const phoneNumberValue = phoneNumber;
    return { dialCode: null, phoneNumber: phoneNumberValue.replace(/\D/g, '') };
  }

  return { dialCode: null, phoneNumber: null };
}

export function validatePhoneNumberLogin(
  phoneNumber: string,
  dialCodeList: CountryDialCode[]
): { dialCode: string | null; phoneNumber: string | null } {
  if (phoneNumber.startsWith('+')) {
    const dialCodeLength = [4, 3, 2].find(
      length => dialCodeList.findIndex(el => el.code === phoneNumber.substring(1, length)) !== -1
    );

    if (dialCodeLength) {
      const dialCode = phoneNumber.substring(1, dialCodeLength);
      const phoneNumberValue = phoneNumber.slice(dialCodeLength).replace(/\D/g, '').replace(/^0/, '');

      return { dialCode, phoneNumber: phoneNumberValue };
    }
  }

  if (phoneNumber.startsWith('00')) {
    const dialCodeLength = [5, 4, 3].find(
      length => dialCodeList.findIndex(el => el.code === phoneNumber.substring(2, length)) !== -1
    );

    if (dialCodeLength) {
      const dialCode = phoneNumber.substring(2, dialCodeLength);
      const phoneNumberValue = phoneNumber.slice(dialCodeLength).replace(/\D/g, '').replace(/^0/, '');

      return { dialCode, phoneNumber: phoneNumberValue };
    }
  }

  if (phoneNumber.startsWith('0') && phoneNumber[1] !== '0') {
    const phoneNumberValue = phoneNumber.slice(1);
    return { dialCode: GERMANY_COUNTRY_CODE, phoneNumber: phoneNumberValue.replace(/\D/g, '') };
  }

  if (!phoneNumber.startsWith('0') && phoneNumber[0] !== '+') {
    const phoneNumberValue = phoneNumber;
    return { dialCode: GERMANY_COUNTRY_CODE, phoneNumber: phoneNumberValue.replace(/\D/g, '') };
  }

  return { dialCode: GERMANY_COUNTRY_CODE, phoneNumber: null };
}

/**
 * Check if the customer's age is over the allowed age (18+).
 *
 * @remarks
 * allowedAge, birthDate and todayDate are converted to milliseconds.
 *
 * @returns Validation error if customer's age is under the allowed age, null otherwise.
 */
export function checkAllowedAge(): ValidatorFn {
  const allowedAge = 568025136000; // 18 years old
  return (control: AbstractControl): ValidationErrors | null => {
    const birthDate = new Date(moment(control.value).format('YYYY-MM-DD')).getTime(); // birth date in milliseconds
    const todayDate = new Date(moment(Date.now()).format('YYYY-MM-DD')).getTime(); // today date in milliseconds
    return todayDate - birthDate > allowedAge ? null : { ageRestricted: true };
  };
}
